<template>
  <section id="colors">
    <h1>Couleurs</h1>
    <div class="columns items is-flex-wrap-wrap mt-6">
      <div class="item column is-3">
        <div class="box-color color-1"></div>
        <span class="hex"><strong>{{ color1 }}</strong></span>
        <span class="rvb">R23 V52 B240</span>
        <span class="cmjn">C86 M76 J0 N0</span>
      </div>
      <div class="item column is-3">
        <div class="box-color color-2"></div>
        <span class="hex"><strong>{{ color2 }}</strong></span>
        <span class="rvb">R82 V206 B245</span>
        <span class="cmjn">C64 M0 J3 N0</span>
      </div>
      <div class="item column is-3">
        <div class="box-color color-3"></div>
        <span class="hex"><strong>{{ color3 }}</strong></span>
        <span class="rvb">R130 V122 B246</span>
        <span class="cmjn">C59 M58 J0 N0</span>
      </div>
      <div class="item column is-3">
        <div class="box-color color-4"></div>
        <span class="hex"><strong>{{ color4 }}</strong></span>
        <span class="rvb">R226 V117 B135</span>
        <span class="cmjn">C0 M72 J29 N0</span>
      </div>
      <div class="item column is-3">
        <div class="box-color color-5"></div>
        <span class="hex"><strong>{{ color5 }}</strong></span>
        <span class="rvb">R0 V63 B191</span>
        <span class="cmjn">C92 M81 J0 N0</span>
      </div>
      <div class="item column is-3">
        <div class="box-color color-6"></div>
        <span class="hex"><strong>{{ color6 }}</strong></span>
        <span class="rvb">R246 V246 B253</span>
        <span class="cmjn">C2 M2 J0 N0</span>
      </div>
      <div class="item column is-3">
        <div class="box-color color-7"></div>
        <span class="hex"><strong>{{ color7 }}</strong></span>
        <span class="rvb">R8 V5 B50</span>
        <span class="cmjn">C94 M92 J43 N62</span>
      </div>
      <div class="item column is-3">
        <div class="box-color color-8"></div>
        <span class="hex"><strong>{{ color8 }}</strong></span>
        <span class="rvb">R9 V20 B32</span>
        <span class="cmjn">C86 M74 J57 N74</span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Colors",
  data() {
    return {
      color1: "#1734f0",
      color2: "#52cef7",
      color3: "#827af6",
      color4: "#e27587",
      color5: "#003fbf",
      color6: "#f6f6fd",
      color7: "#080532",
      color8: "#091420"
    }
  }, 
  methods: {

  }
};
</script>