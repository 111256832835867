<template>
  <section id="modeles">
    <h1>Modèles Templates</h1>
    <h2 class="mb-6">Sélectionnez les fichiers que vous souhaitez télécharger</h2>
    <div class="columns">
      <div class="column is-4">
        <h4>Template - Word</h4>
        <div class="form-group">
          <input
            type="checkbox"
            id="word-tpl"
            class="invisible"
            value="AND-word-TPL.dotx"
            v-model="modelName"
          />
          <label for="word-tpl" class="checkbox box"
            ><img
              class="big"
              src="/tpl/thumb-word-tpl.png"
              alt="Anderson Word Template thumbnail"
            />
          </label>
        </div>
      </div>
      <div class="column is-4">
        <h4>Template - Powerpoint</h4>
        <div class="form-group">
          <input
            type="checkbox"
            id="ppt-tpl"
            class="invisible"
            value="AND-powerpoint-TPL.potx"
            v-model="modelName"
          />
          <label for="ppt-tpl" class="checkbox box"
            ><img
              class="big"
              src="/tpl/thumb-ppt-tpl.png"
              alt="Anderson Powerpoint Template thumbnail"
            />
          </label>
        </div>
      </div>
      <div class="column is-4">
        <h4>Template - Autres</h4>
        <div class="form-group"></div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <div class="columns is-justify-content-space-between is-align-items-center">
          <div class="column is-3">
            
          </div>
          <div class="column is-3">
            <button
              v-if="!isEmptyName"
              class="button submit primary is-medium"
              @click="onClickDownload()"
            >
              Télécharger
            </button>
          </div>
        </div>
        <div v-show="clicked">
          <progress class="progress is-small" value="" max="100"></progress>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { saveAs } from "file-saver";
import JSZip from "jszip";
export default {
  name: "Modeles",
  data() {
    return {
      page: "Modeles",
      modelName: [],
      clicked: false
    };
  },
  methods: {
    onClickDownload() {
      this.clicked = !this.clicked;
      var now = new Date();
      var res = now.toISOString().slice(0, 10).replace(/-/g, "");
      var pathToAssets = "/tpl/";
      const URLS = this.modelName.map((i) => pathToAssets + i);
      saveToZip("assets-" + this.page + "-" + res + ".zip", URLS);

      function saveToZip(filename, urls) {
        const zip = new JSZip();
        const folder = zip.folder("Modeles");
        urls.forEach((url) => {
          const blobPromise = fetch(url).then((r) => {
            if (r.status === 200) return r.blob();
            return Promise.reject(new Error(r.statusText));
          });
          const name = url.substring(url.lastIndexOf("/"));
          folder.file(name, blobPromise);
        });

        zip
          .generateAsync({ type: "blob" }, function updateCallback(metadata) {
            var progressBar = document.querySelector(".progress");
            var progressBarValue = metadata.percent.toFixed(2);
            progressBar.setAttribute("value", progressBarValue);
            progressBar.innerHTML = progressBarValue + "%";
          })
          .then((blob) => saveAs(blob, filename))
          .catch((e) => console.log(e));
      }
    },
  },
  computed: {
    isEmptyName: ({ modelName }) => modelName.length === 0,
  },
};
</script>
<style scoped>
.big {
  min-height: 8rem;
  width: auto;
  height: 100%;
}
</style>