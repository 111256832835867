<template>
  <section id="graphics">
    <div class="title-area">
      <h1>Éléments graphiques</h1>
      <h2 class="mb-6">
        Sélectionnez les fichiers que vous souhaitez télécharger
      </h2>

      <!-- <label for="selectall">
        <input
          type="checkbox"
          class="classic"
          id="selectall"
          @click="selectAll()"
        />
        <small>Tout selectionner</small>
      </label> -->
    </div>
    <ul class="elements-area columns">
      <li
        class="column is-4"
        v-for="(image, index) in importFiles()"
        :key="index"
      >
        <div class="form-group">
          <input
            type="checkbox"
            :id="image"
            :value="image"
            v-model="graphicsToZip"
            class="invisible"
          />
          <label class="box checkbox" :for="image">
            <img :src="`/images/graphics/${image}.png`" alt="" />
          </label>
        </div>
      </li>
    </ul>
    <div class="extension-area columns">
      <div class="column is-12">
        <h4>Extension</h4>

        <div class="columns">
          <div class="form-group column is-2">
            <input
              type="radio"
              id="png"
              name="ext"
              value=".png"
              v-model="graphicsExt"
              class="invisible"
            />
            <label for="png" class="box radio"> PNG </label>
          </div>
          <div class="form-group column is-2">
            <input
              type="radio"
              id="jpg"
              name="ext"
              value=".jpg"
              v-model="graphicsExt"
              class="invisible"
            />
            <label for="jpg" class="box radio"> JPG </label>
          </div>
          <div class="form-group column is-2">
            <input
              type="radio"
              id="svg"
              name="ext"
              value=".svg"
              v-model="graphicsExt"
              class="invisible"
            />
            <label for="svg" class="box radio"> SVG </label>
          </div>
          <button
            v-if="!isEmptyName && !isEmptyExt"
            class="button submit primary is-large"
            @click="onClickDownload()"
          >
            Télécharger
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { saveAs } from "file-saver";
import JSZip from "jszip";
export default {
  name: "Graphics",
  data() {
    return {
      page: "Graphics",
      images: [],
      graphicsToZip: [],
      graphicsExt: [],
    };
  },
  methods: {
    importFiles() {
      let images = require.context(
        "@/assets/images/graphics/",
        false,
        /^.*\.png$/
      );
      let array = (images = images.keys());
      images = array.map((e) => e.substring(2, e.length - 4));
      return images;
    },

    onClickDownload() {
      var now = new Date();
      var res = now.toISOString().slice(0, 10).replace(/-/g, "");
      var pathToAssets = "/images/graphics/";
      const URLS = this.graphicsToZip.map((i) => pathToAssets + i + this.graphicsExt);
      saveToZip("assets-" + this.page + "-" + res + ".zip", URLS);

      function saveToZip(filename, urls) {
        const zip = new JSZip();
        const folder = zip.folder("graphics");
        urls.forEach((url) => {
          const blobPromise = fetch(url).then((r) => {
            if (r.status === 200) return r.blob();
            return Promise.reject(new Error(r.statusText));
          });
          const name = url.substring(url.lastIndexOf("/"));
          folder.file(name, blobPromise);
        });

        zip
          .generateAsync({ type: "blob" })
          .then((blob) => saveAs(blob, filename))
          .catch((e) => console.log(e));
      }
    },

    selectAll() {},
  },
  computed: {
    isEmptyName: ({ graphicsToZip }) => graphicsToZip.length === 0,
    isEmptyExt: ({ graphicsExt }) => graphicsExt.length === 0,
  },
};
</script>
<style scoped>
.columns {
  flex-flow: row wrap;
}
.title-area {
  height: 15%;
}
.elements-area {
  height: 70%;
  /* margin: 4rem 0 2rem; */
  overflow-y: scroll;
  padding-bottom: 2rem;
}
.extension-area {
  margin-top: 2rem;
  border-top: 1px solid lightgrey;
  height: 15%;
}
img {
  height: 120px;
  width: auto;
}

.submit {
  margin-left: auto;
}
</style>
