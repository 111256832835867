<template>
  <section id="typography">
    <h1>Typographies</h1>
    <div class="columns mt-6">
      <div
        v-for="typo in typos"
        :key="typo.slug"
        class="column is-5"
        :class="typo.type"
      >
        <h4>{{ typo.type }}</h4>
        <h2 class="h1">Aa</h2>
        <h3 class="h2">{{ typo.title }}</h3>
        <p>{{ typo.description }}</p>
        <div class="alphabet">
          <span>Aa</span>
          <span>Bb</span>
          <span>Cc</span>
          <span>Dd</span>
          <span>Ee</span>
          <span>Ff</span>
          <span>Gg</span>
          <span>Hh</span>
          <span>Ii</span>
          <span>Jj</span>
          <span>Kk</span>
          <span>Ll</span>
          <span>Mm</span>
          <span>Nn</span>
          <span>Oo</span>
          <span>Pp</span>
          <span>Qq</span>
          <span>Rr</span>
          <span>Ss</span>
          <span>Tt</span>
          <span>Uu</span>
          <span>Vv</span>
          <span>Ww</span>
          <span>Xx</span>
          <span>Yy</span>
          <span>Zz</span>
        </div>
        <span class="numbers">1 2 3 4 5 6 7 8 9</span>
        <button
          class="button submit primary is-medium mt-6"
          @click="onClickDownload(typo.slug)"
        >
          Télécharger
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "Typography",
  data() {
    return {
      page: "Typography",
      typos: [
        {
          title: "Proxima Nova",
          type: "primary",
          slug: "proxima",
          description:
            "La Proxima Nova est utilisée pours les titres et les sous-titres.",
        },
        {
          title: "Karla",
          type: "secondary",
          slug: "karla",
          description: "La Karla est utilisée pour les contenus.",
        },
      ],
    };
  },
  methods: {
    onClickDownload(typo) {
      axios({
        url: `/typo/${typo}.zip`,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fURL = document.createElement("a");

        fURL.href = fileURL;
        fURL.setAttribute("download", `${typo}.zip`);
        document.body.appendChild(fURL);

        fURL.click();
      });
    },
  },
};
</script>